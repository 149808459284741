.btn {
    @apply ring-2 ring-emerland-700 rounded-sm text-lg text-gray-600 font-semibold px-2 py-0.5 mr-4;
}

.btn:hover {
    @apply bg-emerland-700 text-white;
}

.btn-active {
    @apply bg-emerland-700 text-white;
}

